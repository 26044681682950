<template>
  <div class="logout" ref="logout">
    <div class="content">
      <br/>
      <span class="bold">在您确认注销账户之前，请充分阅读理解并同意以下事项，尤其是加粗内容。如您不同意任一内容，请勿点击账户注销操作。您通过页面点击申请并同意注销，视为您同意并接受本提醒页面全部内容：</span>
      <br/>
      <br/>
      1、您所申请注销的账户应当是您依照与平台的约定注册由平台提供给您本人的账户，并有权决定注销与否，而不侵犯第三方合法权益。否则由此引发的纠纷概由您个人承担。
      <br/>
      <br/>
      2、申请注销前，您应当确保账户处于正常状态，无任何拖欠的款项、工资，无进行中的纠纷或处罚，已解除与其他APP、网站的登陆、使用或绑定等关系，不存在任何由于该账户被注销而导致未了结合同关系与其他基于该账户的存在而产生或维持的权利义务，并符合其他平台及其关联方平台账户注销条件。
      <br/>
      <br/>
      3、若您账户内存在未完结的订单、佣金或尚有未提现的收益，建议您在注销前提现完毕账户收益后在注销，若您仍执意要注销的，则视为您自愿放弃未完结的订单、佣金、收益或已提现未到账收益。
      <br/>
      <br/>
      4、注销完成后，即意味着双方权利义务的终止，平台不再为您提供本服务，同时，还可能对于该账户产生包括但不限于如下结果：（1）您将无法再次使用该账户登陆鲸选师或鲸选师关联公司以及其他使用该账户登陆的网站及客户端进行任何操作；（2）账户身份、用户信息与会员权益等将会永久清除且无法恢复；（3）佣金、团员和订单数据等将会永久清除且无法恢复；（4）您账户内的所有个人历史订单记录将被清除并无法再次找回；（5）账户内所有优惠券、虚拟币、电子券将作废；（6）部分所注册的账户、微信和授权的淘宝账户在申请日后3个月内将无法再次注册鲸选师及其关联公司的平台。
      <br/>
      <br/>
      5、禁止通过注销鲸选师App账号破坏鲸选师推广规则及体系健康发展的方式。
      <br/>
      <br/>
      6、您应当按照平台公示的流程进行注销，且您承诺该账户注销申请一经提交，您不会以任何理由要求平台予以撤销，平台对此不承担任何责任。
      <br/>
      <br/>
      7、本提示未尽事宜，平台依照与您约定的《服务协议》《隐私政策》及其他规则执行。
      <br/>
      <br/>
    </div>
  </div>
</template>
<script>

export default {
  name: 'logout',
  data () {
    return {}
  },
  created () {
  },
  methods: {}
}
</script>
<style lang="less" scoped>
.logout {
  width: 100%;
  min-height: 100vh;

  .content {
    margin-top: 18px;
    padding: 0 18px;
    font-size: 15px;
    color: #333333;
    line-height: 21px;
    color: #333333;
    font-family: PingFang-SC-Regular, PingFang-SC;

    .bold {
      font-weight: bold;
    }
  }
}
</style>
